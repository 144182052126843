import React from "react"
import { Dialog } from "reakit/Dialog"
import { Button } from "app/wellzesta-ui"
import CloseIcon from "app/common/icons/Close"
import { useFeedbackState } from "./FeedbackContext"
import { useMobile, useTableDevice, useTablet } from "app/lib/hooks/useBreakpoints"
import clsx from "clsx"

export default function FeedbackDialog() {
  const { dialogState, close, feedback, attendance } = useFeedbackState()
  const isMobile = useMobile()
  const isTablet = useTablet()
  const isTabletDevice = useTableDevice() // this is because iPad Pro landscape is large enough to be confused with a laptop

  const isMobileOrTablet = isMobile || isTablet || isTabletDevice

  const renderCloseButton = () => {
    return isMobileOrTablet ? (
      <Button
        theme="inverse"
        size="none"
        onClick={close}
        aria-label="close"
        className={clsx("ml-4 order-1 ml-auto", {
          "rounded-full px-3 float-right": isMobileOrTablet,
        })}
      >
        <CloseIcon className="w-5 h-5" />
      </Button>
    ) : (
      <CloseIcon className="w-5 h-5 cursor-pointer float-right" onClick={close} />
    )
  }

  return (
    <Dialog
      {...dialogState}
      aria-label="Feedback"
      className={clsx({
        "fixed right-0 bottom-0 size-16 py-2 bg-white z-main shadow w-1/5 mr-8 h-[300px]": !isMobileOrTablet,
        "w-full shadow bg-white fixed z-50": isMobileOrTablet
      })}
      hideOnClickOutside={false}
      tabIndex={0}
    >
      <div className={`py-4 px-4 mx-auto md:max-w-screen-xl flex flex-wrap ${isMobileOrTablet ? "flex-row" : "flex-col"}`}>
        {/* When not mobile, show close button first */}
        {!isMobileOrTablet && (
          <div className={`inline-block float-right ${isMobileOrTablet ? "w-1/4" : "w-full"}`}>
            {renderCloseButton()}
          </div>
        )}

        <div className={clsx({
          "inline-block": true,
          "w-full": !isMobileOrTablet,
          "w-3/4": isMobileOrTablet
        })}>
          {feedback ? feedback?.bodyElement : attendance?.bodyElement}
        </div>

        {/* When mobile, show close button last */}
        {isMobileOrTablet && (
          <div className="inline-block float-right w-1/4">
            {renderCloseButton()}
          </div>
        )}
      </div>
    </Dialog>
  )
}
