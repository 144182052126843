import rollbar from "app/lib/rollbar"

const formatErrorMessage = ({ graphQLErrors, networkError, operation }) => {
  let errorMessage = ""

  if (operation) {
    errorMessage += `Error while executing ${operation.operationName} operation:\n`
    errorMessage += `  variables: ${JSON.stringify(operation.variables)}\n\n`
  }

  if (graphQLErrors) {
    const formatPath = (path) =>
      path && path.length ? path.join(".") + ":" : ""

    errorMessage += graphQLErrors
      .map((error) => {
        if (error.path) {
          return `  ${formatPath(error.path)} ${error.type}`
        }

        return (
          `${error.message}` +
          "\n\n" +
          (error.problems || [])
            .map((p) => `  ${formatPath(p.path)} ${p.explanation}`)
            .join("\n")
        )
      })
      .join("\n")
  }

  if (networkError) {
    errorMessage += networkError.message
  }

  return errorMessage
}

class ApolloError extends Error {
  constructor(error) {
    super(formatErrorMessage(error))

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApolloError)
    }

    Object.assign(this, error)
  }
}

const isApolloError = (error) => "graphQLErrors" in error

const isAbortError = (error) => {
  return error.name === 'AbortError' ||
    error.message?.includes('aborted') ||
    (error.networkError?.name === 'AbortError') ||
    (error.networkError?.message?.includes('aborted'))
}

export default function reportError(error, req) {
  error = isApolloError(error) ? new ApolloError(error) : error

  if (isAbortError(error)) {
    console.error('[AbortError]', error)
    return
  }

  rollbar.error(error, req)
}
