import React from "react"
import { gql, useMutation } from "@apollo/client"
import { Text, Button } from "app/wellzesta-ui"
import DatePrinter from "app/common/DatePrinter"
import { useMobile } from "app/lib/hooks/useBreakpoints"

const confirmEventAttendanceMutation = gql`
  mutation ConfirmEventAttendance($eventId: ID!, $attended: Boolean!) {
    confirmEventAttendance(eventId: $eventId, attended: $attended) {
      id
    }
  }
`

export default function EventFeedbackBody({
  title,
  event,
  onCancel,
  onConfirm,
}) {
  const [confirmEventAttendance, { loading, error }] = useMutation(
    confirmEventAttendanceMutation
  )
  const isMobile = useMobile()

  return (
    <>
      <div>
        <Text size="lg" className="block font-bold leading-2">
          {title}
        </Text>
        {!isMobile && (<br />)}
        <Text size={"md"} className={`block ${isMobile ? "font-normal" : "font-semibold text-gray-500"}`}>
          {event.title + " "}
          <DatePrinter value={event.startedAt} format="MMM DD, h:mm A" />
        </Text>

        {event?.attended && (
          <Text size={"md"} className={`block mt-4 ${isMobile ? "font-normal" : "font-semibold text-gray-500"}`}>
            Please rate the event.
          </Text>
        )}
      </div>

      {error && (
        <Text role="alert" size="sm" className="block my-2 text-red-200">
          Something happened! Please, try again.
        </Text>
      )}

      <div className="pt-6 flex space-x-3 ml-auto w-full order-2">
        <Button
          theme="tertiary"
          size="sm"
          disabled={loading}
          onClick={() => {
            if(event?.attended) {
              onCancel()
            } else {
              confirmEventAttendance({
                variables: { eventId: event.id, attended: false },
              }).then(onCancel)
            }
          }
            
          }
          className={"w-full bg-gray-800 hover:bg-gray-900 hover:text-white text-white"}
        >
          {event?.attended ? "Later" : "No"}
        </Button>
        <Button
          theme="primary"
          size="sm"
          disabled={loading}
          onClick={() =>
            confirmEventAttendance({
              variables: { eventId: event.id, attended: true },
            }).then(onConfirm)
          }
          className={"w-full"}
        >
          {event?.attended ? "Ok" : "Yes"}
        </Button>
      </div>
    </>
  )
}
