import React from "react"
import { gql, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import { Text, Button, Heading, Input } from "app/wellzesta-ui"
import DatePrinter from "app/common/DatePrinter"
import RateInput from "app/common/RateInput"
import ToggleInput from "app/common/ToggleInput"
import FormField from "app/common/form/FormField"

export const answerQuestionsMutations = gql`
  mutation AnswerAttendanceQuestions($input: [AnswerInput!]!) {
    answerQuestions(input: $input) {
      id
    }
  }
`

const parseOutput = (values, event) => {
  const questionIds = event?.questions?.edges?.map(({ node }) => node.id) || [];
  const initialValues = questionIds.reduce((acc, id) => {
    acc[id] = null;
    return acc;
  }, {});

  const mergedValues = { ...initialValues, ...values };

  return Object.entries(mergedValues)
    .map(([key, value]) => ({
      questionId: key.split("-")[0],
      value: value === null ? null : String(value),
    }));
}

export default function EventQuestions({ event, close }) {
  const [answerQuestions, { error }] = useMutation(answerQuestionsMutations)

  function onSubmit(values) {
    let normalizedValues = parseOutput(values, event)

    return answerQuestions({ variables: { input: normalizedValues } }).then(
      close
    )
  }

  return (
    <div className="w-full md:max-w-screen-md md:mx-auto md:mt-12">
      <div className="p-4 border-b border-beige-200 text-center w-full mt-5">
        <Heading size="sm" className="font-bold">
          {event.title}
        </Heading>
        <Text size="md" className="block font-semibold">
          <DatePrinter value={event.startedAt} format="MMM DD, h:mm A" />
        </Text>
      </div>
      <Formik onSubmit={onSubmit} initialValues={{}}>
        {({ isSubmitting }) => (
          <Form className="flex flex-col">
            {error && (
              <div
                role="alert"
                className="w-full bg-red-300 text-white text-sm font-bold p-4 uppercase"
              >
                Something went wrong! Please try again.
              </div>
            )}
            <div className="space-y-6 md:space-y-10 mt-4 md:mt-6 flex-grow px-4 pb-16">
              {event.questions.edges.map(
                ({ node: { id, questionType, title } }) => (
                  <QuestionField
                    key={id}
                    name={id}
                    label={title}
                    questionType={questionType}
                  />
                )
              )}
            </div>
            <div className="flex justify-end space-x-3 flex-grow-0 flex-shrink-0 border-t border-gray-200 p-4">
              {error && (
                <Text
                  role="alert"
                  size="sm"
                  className="block my-2 text-red-200"
                >
                  Something happened! Please, try again.
                </Text>
              )}
              <Button
                type="submit"
                disabled={isSubmitting}
                theme="tertiary"
                size="md"
              >
                Done
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function QuestionField({ name, label, questionType }) {
  if (questionType === "RATING") {
    return <FieldSet name={name} label={label} component={RateInput} />
  }

  if (questionType === "BOOLEAN") {
    return <FieldSet name={name} label={label} component={ToggleInput} />
  }

  return (
    <div className="mx-auto">
      <Text
        size="md"
        as="label"
        htmlFor={name}
        className="block mb-2 text-center"
      >
        {label}
      </Text>
      <FormField name={name} component={TextInput} />
    </div>
  )
}

function FieldSet({ label, name, component }) {
  return (
    <fieldset className="space-y-4 flex flex-col items-center">
      <Text size="md" as="legend" htmlFor={name} className="block text-center">
        {label}
      </Text>
      <FormField name={name} component={component} />
    </fieldset>
  )
}

function TextInput(props) {
  return (
    <Input
      {...props}
      className="px-3 py-2 bg-inverse focus:bg-white w-full md:max-w-xl block md:mx-auto"
      placeholder="Type here"
    />
  )
}
