import React from "react"
import { Button as ReakitButton } from "reakit"
import clsx from "clsx"

const Button = React.forwardRef(
  (
    {
      children,
      size = "md",
      theme = "primary",
      className,
      active,
      disabled,
      type = "button",
      ...props
    },
    ref
  ) => {
    const paddingStyles = {
      xs: "is-xs",
      in: "is-in",
      sm: "is-sm",
      md: "is-md",
      lg: "is-lg",
    }[size]

    const colorStyles = {
      primary: "is-primary",
      secondary: "is-secondary",
      tertiary: "is-tertiary", // is the light button of the theme
      quaternary: "is-quaternary",
      white: "is-white",
      inverse: active ? "is-primary" : "is-inverse", // is the light button of the theme
      transparent: "is-transparent", // default text white (used for back buttons)
      mood: "is-mood-option",
      link: "link-tertiary",
      dark: "is-dark",
      inverseEvent: "is-inverse-event",
      isCheckedIn: "is-checked-in",
      secondaryDisable: "is-secondary-disable"
    }[theme]

    return (
      <ReakitButton
        ref={ref}
        type={type}
        className={clsx(
          "btn action focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-blue-400",
          className,
          paddingStyles,
          colorStyles,
          disabled && "cursor-not-allowed"
        )}
        disabled={disabled}
        {...props}
      >
        {children}
      </ReakitButton>
    )
  }
)
export default Button
