

export const routesFilter = (route, roles) => {
    const { label, items } = route;
    const { independentLiving, staff, futureResidentCommited, futureResidentWaitlisted, atHome } = roles;
    
    if (independentLiving || staff) return items; 


    switch (label) {
        case "Connect":
            return items.filter((item) => item.label === "Directory"); // Keep only "Directory"
        case "Resources":
            return items.filter((item) => item.label !== "Concierge"); // Remove "Concierge"
        case "My Space":
            return items.filter((item) => {
                // Keep "Service Requests Log" if futureResident or atHome is true
                if (item.label === "Service Requests Log") {
                    return futureResidentCommited || futureResidentWaitlisted || atHome;
                }
                return true; // Keep all other items
            });
        default:
            return items; // Keep all items for other sections
    }
    
};


export const shouldIncludeRoute = (route, roles) => {
    // Exclude "Connect" if futureResident or atHome is true
    const { futureResidentCommited,futureResidentWaitlisted, atHome } = roles;
    if ((futureResidentCommited || futureResidentWaitlisted || atHome ) && route.label === "Connect") {
      return false;
    }
    return true;
  };
  
export const enhanceRoute = (route, roles) => ({
    ...route,
    items: routesFilter(route, roles),
  });