import { getAppVersion } from "app/utils"

export const WZ_FEEDBACK = "wz_feedback"
export const _P_BULLETIN_BOARD_ORGS = "_p_bulletin_board_orgs"
export const _P_EVENTS_VENUE_FILTER_ORGS = "_p_events_venue_filter_orgs"
export const _P_FULL_COUNT_ORGS = "_p_full_count_orgs"
export const _P_LANDING_PAGES = "_p_landing_pages";

export const L_O_AUTO_REFRESH_INTERVAL_SECONDS = "l_o_auto_refresh_interval_seconds"

export const L_O_LIFE_VERSION = "L_O_LIFE_VERSION"

export const _P_HIDE_MOOD_DIALOG = "_p_hide_mood_dialog"

export const L_P_ENABLE_ROLLBAR_FOR_LIFE = "l_p_enable_rollbar_for_life"

// Elastic Search constants
export const L_P_HS_CALIBRATION_PANEL_ENABLED = "l_p_hs_calibration_panel_enabled"
export const L_R_DIRECTORY_SEARCH_CATEGORY_REWORK = "l_r_directory_search_category_rework"
export const L_R_DIRECTORY_SEARCH_CATEGORY_ORDER = "l_r_directory_search_category_order"

// Feature flag to manage video player logs
export const _OP_VIDEO_PLAYER_LOGS_ON = "_op_video_player_logs_on"

// Feature flag To Lock Profile Fields
export const L_P_LOCK_PROFILE_FIELDS = "l_p_lock_profile_fields"

export const DEFAULT_FEATURE_FLAG_STATE = {
  wz_feedback: "true",
  l_o_auto_refresh_interval_seconds: 1200,
  L_O_LIFE_VERSION: getAppVersion().toString(),
  _p_bulletin_board_orgs: "",
  _p_events_venue_filter_orgs: "",
  _p_landing_pages: false,
  _p_full_count_orgs: "",
  l_p_hs_calibration_panel_enabled: false,
  _p_hide_mood_dialog: "",
  l_r_directory_search_category_rework : false,
  l_r_directory_search_categogy_order: {"fields": []},
  l_p_enable_rollbar_for_life: false,
  l_p_lock_profile_fields: ""
}
