import clsx from "clsx"
import React from "react"
import Lock from "app/common/icons/Lock"

function Input({ className, disabled, settings = "bg-gray-300", icon: Icon, ...props }) {
  return (
    <div className="relative w-full">
      {disabled && (
        <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
          <Lock className="w-5 h-5 text-gray-600" />
        </div>
      )}
      <input
        className={clsx(
          `px-4 pr-10 w-full ${disabled ? "text-gray-500" : ""} rounded h-11 ${settings} font-bold border-2 border-transparent focus:border-brand-blue-light placeholder-gray-600`,
          className,
          disabled && "cursor-not-allowed"
        )}
        disabled={disabled}
        {...props}
      />
    </div>
  )
}

export function Textarea({
  name,
  value,
  onChange,
  rows = "3",
  cols = "50",
  placeholder,
}) {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      className={clsx(
        "px-2 pt-1 block w-full bg-gray-300 rounded font-semibold border-2 border-transparent focus:border-brand-blue-light placeholder-gray-600"
      )}
      style={{ resize: "none" }}
    />
  )
}

export default Input
