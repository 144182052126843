import { Text } from "../text/Text"

/*
TODO: Discuss a better way to pass IDs to elements (data-testid, etc)
...props = { id?: string }
*/

const CardTitle = ({ lines = 2, children, className, ...props }) => {
  return (
    <Text as="h2" size="lg" className={`font-bold line-clamp-${lines} ${className}`} {...props}>
      {children}
    </Text>
  )
}

export default CardTitle
