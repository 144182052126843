import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import { useFeatureFlags } from "app/lib/hooks/useFeatureFlags"
import { useCurrentUser } from '../CurrentUser';
import { L_P_ENABLE_ROLLBAR_FOR_LIFE } from '../FeatureFlags/Constants'
import initializeRollbar from '../rollbar';
const RollbarContext = createContext(null)

export const useRollbar = () => {
    return useContext(RollbarContext)
}

const RollbarProvider = ({ children }) => {
    const [rollbar, setRollbar] = useState(null)
    const { getFeatureFlag } = useFeatureFlags()
    const currentUser = useCurrentUser()
    const rollbarInitialized = useRef(false)
    const enabledOrgs = getFeatureFlag(L_P_ENABLE_ROLLBAR_FOR_LIFE)

    useEffect(() => {
        if (!rollbarInitialized.currentUser && enabledOrgs !== "" && currentUser) {
            const organizationId = currentUser.organization?.id;
            const enabledOrgsArray = enabledOrgs.split(",")
            if (enabledOrgsArray.includes(organizationId) || enabledOrgsArray.includes("all")) {
                initializeRollbar(true).then((rollbarInstance) => {
                    
                    if (rollbarInstance) {
                        setRollbar(rollbarInstance)
                        window.rollbar = rollbarInstance
                        rollbarInitialized.current = true
                        console.log("Rollbar initialized");
                    }
                }).catch((error) => {
                    console.error("Failed to initialize Rollbar:", error)
                })
            }else{
                console.log("Rollbar is not enabled for this organization")
            }
        }

    }, [enabledOrgs, currentUser])

    return (
        <RollbarContext.Provider value={rollbar}>
            {children}
        </RollbarContext.Provider>
    )
}

export default RollbarProvider