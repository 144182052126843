import React from "react"
import { Text } from "app/wellzesta-ui"
import Title from "./CardTitle"
import CardCanceledOverlay from "./CardCanceledOverlay"
import styles from "./Card.module.css"
import FixedRatioImage from "app/common/FixedRatioImage"
import clsx from "clsx"

const Card = React.forwardRef(
  ({ href, target, onClick, onMouseEnter, ...props }, ref) => (
    <a
      ref={ref}
      href={href}
      target={target}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <div
        className={clsx(
          styles.card,
          "overflow-hidden flex flex-col h-full hover:text-primary focus:text-tertiary"
        )}
        {...props}
      />
    </a>
  )
)

Card.Content = (props) => (
  <div className="flex-grow overflow-hidden p-4 pr-0" {...props} />
)
Card.Title = Title
Card.Subtitle = (props) => (
  <Text
    size="sm"
    as="p"
    className="font-bold text-gray-500 truncate"
    {...props}
  />
)
Card.Description = ({ className, ...props }) => (
  <Text
    size="sm"
    as="p"
    className={`font-normal text-gray-500 truncate ${className}`}
    {...props}
  />
)

const Image = (props) => (
  <FixedRatioImage style={{ maxHeight: "169px" }} {...props} />
)

Card.HomeImage = (props) => (
  <FixedRatioImage style={{ maxHeight: "500px" }} {...props} />
)

Card.HomeBigImage = (props) => (
  <FixedRatioImage style={{ height: "500px" }} {...props} />
)

Card.Image = Image
Card.CanceledOverlay = CardCanceledOverlay

export default Card
