const Rollbar = require("rollbar")

const token = process.browser
  ? process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN
  : process.env.ROLLBAR_ACCESS_TOKEN

async function initializeRollbar(enabled) {
  let rollbar = null

  if (!enabled) {
    return null
  }

  rollbar = new Rollbar({
    enabled: true,
    accessToken: token,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NEXT_PUBLIC_ENV,
    },
  })

  rollbar.setUser = (user) => {
    rollbar.configure({ payload: { person: user } })
  }

  if (process.env.NEXT_PUBLIC_ENV === "development") {
    // On development we log rollbar requests so we can debug which errors are being reported without spamming the logs
    rollbar.client.queue.api.transport = {
      get(...args) {
        console.log("[rollbar]", "GET", ...args)
      },
      post(accessToken, options, payload) {
        console.log("[rollbar]", "POST", payload.data.body)
      },
    }
  }

  return rollbar

}

module.exports = initializeRollbar
